import { Grid } from "@material-ui/core";
import axios from "axios";
import React, { useState } from "react";
import { Button, FormControl } from "react-bootstrap";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import { config } from "../../config";
import JalilahArabicTemplate from "./JalilahArabicTemplate";
import jalilahEnglishTemplate from "./JalilahEnglishTemplate";
import styles from "./Jalillah.module.css";
function Jalillah() {
  const [jalillahData, setJalillahData] = useState({
    name: "",
    title: "",
    description: "",
    mainImgUrl: "",
    imageLink: "",
    arabicImage: "",
    englishImage: "",
    target: "",
    keyword: "",
    startDate: "",
    endDate: "",
    donationLink: "",
  });
  const [englishLPdata, setEnglishLPdata] = useState(null);
  const [arabicLPdata, setArabicLPdata] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [popup, setPopup] = useState(false);
  const handleClosePopup = () => {
    setPopup(false);
    location.reload();
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setJalillahData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const { name } = e.target;
    // Update state directly with file object
    setJalillahData((prevState) => ({
      ...prevState,
      [name]: file,
    }));
  };
  const handleVisitEnglishPage = () => {
    window.open(englishLPdata?.data.link, "_blank");
  };
  const handleVisitArabicPage = () => {
    window.open(arabicLPdata?.data.link, "_blank");
  };
  const apiLink = `${config.appgainServer}/apps/${config.appId}/landingpages`;
  const apiKey = config.appApiKey;
  const submitValuesToCreateJalillah = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (!jalillahData.arabicImage || !jalillahData.englishImage) {
      setIsLoading(false);
      return; // todo: notify user
    }
    let uploadedArabicImage;
    let uploadedEnglishImage;
    const formData = new FormData();
    const formDataEnglish = new FormData();
    formData.append("file", jalillahData.arabicImage);
    formDataEnglish.append("file", jalillahData.englishImage);
    try {
      const arabicImageData = await axios.post(
        "https://adminapi.appgain.io/aws-cdn.appgain-uploader?folder=63760620a902ad017e51ca8b&bucket=content.quantatel.com",
        formData,
        {
          headers: {
            "x-api-key": "appgainapikey1024",
          },
        }
      );

      const englishImageData = await axios.post(
        "https://adminapi.appgain.io/aws-cdn.appgain-uploader?folder=63760620a902ad017e51ca8b&bucket=content.quantatel.com",
        formDataEnglish,
        {
          headers: {
            "x-api-key": "appgainapikey1024",
          },
        }
      );

      console.log(arabicImageData.data.path);
      console.log(englishImageData);
      uploadedArabicImage = `https://cdn.ikhair.net/${arabicImageData.data.path}`;
      uploadedEnglishImage = `https://cdn.ikhair.net/${englishImageData.data.path}`;
      let finalEnglishTemplate = jalilahEnglishTemplate;
      Object.entries(jalillahData).forEach(([key, value]) => {
        if (key === "mainImgUrl") {
          finalEnglishTemplate = finalEnglishTemplate.replaceAll(
            `{{${key}}}`,
            `https://aljalila.ikhair.net/ml/${jalillahData.keyword}-ar`
          );
        } else if (key === "imageLink") {
          finalEnglishTemplate = finalEnglishTemplate.replaceAll(
            `{{${key}}}`,
            uploadedEnglishImage
          );
        } else {
          finalEnglishTemplate = finalEnglishTemplate.replaceAll(
            `{{${key}}}`,
            value
          );
        }
      });
      let finalArabicTemplate = JalilahArabicTemplate;
      Object.entries(jalillahData).forEach(([key, value]) => {
        if (key === "mainImgUrl") {
          finalArabicTemplate = finalArabicTemplate.replaceAll(
            `{{${key}}}`,
            `https://aljalila.ikhair.net/ml/${jalillahData.keyword}`
          );
        } else if (key === "imageLink") {
          finalArabicTemplate = finalArabicTemplate.replaceAll(
            `{{${key}}}`,
            uploadedArabicImage
          );
        } else {
          finalArabicTemplate = finalArabicTemplate.replaceAll(
            `{{${key}}}`,
            value
          );
        }
      });
      const sendingEnglishData = {
        slug: jalillahData.keyword,
        name: jalillahData.name,
        label: jalillahData.name,
        lang: "en",
        socialmedia_settings: {
          title: jalillahData.title,
          description: jalillahData.description,
        },
        web_push_subscription: true,
        image_default: true,
        theme: "code_embed",
        FBpixel_tracking: true,
        twitter_pixel_tracking: false,
        google_ads_pixel_tracking: false,
        linkedIn_pixel_tracking: false,
        snapchat_pixel_tracking: false,
        tiktok_pixel_tracking: false,
        show_in_defPage: false,
        components: [
          {
            type: "basic.h+logo",
          },
          {
            type: "basic.p",
          },
          {
            type: "basic.code_embded_component",
            src: `${finalEnglishTemplate}`,
          },
        ],
      };
      const sendingArabicData = {
        slug: `${jalillahData.keyword}-ar`,
        name: jalillahData.name,
        label: jalillahData.name,
        lang: "en",
        socialmedia_settings: {
          title: jalillahData.title,
          description: jalillahData.description,
        },
        web_push_subscription: true,
        image_default: true,
        theme: "code_embed",
        FBpixel_tracking: true,
        twitter_pixel_tracking: false,
        google_ads_pixel_tracking: false,
        linkedIn_pixel_tracking: false,
        snapchat_pixel_tracking: false,
        tiktok_pixel_tracking: false,
        show_in_defPage: false,
        components: [
          {
            type: "basic.h+logo",
          },
          {
            type: "basic.p",
          },
          {
            type: "basic.code_embded_component",
            src: `${finalArabicTemplate}`,
          },
        ],
      };
      const promises = [
        axios.post(apiLink, sendingEnglishData, {
          headers: {
            "Content-Type": "application/json",
            appApiKey: apiKey,
          },
        }),
        axios.post(apiLink, sendingArabicData, {
          headers: {
            "Content-Type": "application/json",
            appApiKey: apiKey,
          },
        }),
      ];
      const [english, arabic] = await Promise.all(promises);
      setEnglishLPdata(english);
      setArabicLPdata(arabic);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
      setPopup(true);
    }
  };
  if (isLoading) return <LoadingSpinner />;
  return (
    <div>
      <div className="header">
        <h4 className="title">Create Al Jalillah page</h4>
      </div>
      <form
        className="card"
        style={{ padding: "12px 24px" }}
        onSubmit={submitValuesToCreateJalillah}
      >
        <Grid container spacing={3} style={{ padding: "15px 28px" }}>
          <Grid item md={6} sm={12} className="form-group">
            <label htmlFor="name" className="control-label">
              Name
            </label>
            <FormControl
              type="text"
              value={jalillahData.name}
              onChange={handleChange}
              name="name"
              id="name"
              placeholder="Enter name"
              required
            />
          </Grid>
          <Grid item md={6} sm={12} className="form-group">
            <label htmlFor="title" className="control-label">
              Title
            </label>
            <FormControl
              type="text"
              value={jalillahData.title}
              onChange={handleChange}
              name="title"
              id="title"
              placeholder="Enter title"
              required
              maxLength={31}
            />
          </Grid>
          <Grid item md={6} sm={12} className="form-group">
            <label htmlFor="description" className="control-label">
              Description
            </label>
            <FormControl
              type="text"
              value={jalillahData.description}
              onChange={handleChange}
              name="description"
              id="description"
              placeholder="Enter description"
              required
              maxLength={500}
            />
          </Grid>
          <Grid item md={6} sm={12} className="form-group">
            <label htmlFor="arabicImage" className="control-label">
              Arabic Image Link
            </label>
            <FormControl
              type="file"
              onChange={handleFileChange}
              name="arabicImage"
              id="arabicImage"
              placeholder="Enter Arabic image Link"
              required
            />
          </Grid>
          <Grid item md={6} sm={12} className="form-group">
            <label htmlFor="englishImage" className="control-label">
              English Image Link
            </label>
            <FormControl
              type="file"
              onChange={handleFileChange}
              name="englishImage"
              id="englishImage"
              placeholder="Enter English image Link"
              required
            />
          </Grid>
          <Grid item md={6} sm={12} className="form-group">
            <label htmlFor="StartDate" className="control-label">
              Start Date
            </label>
            <FormControl
              type="date"
              value={jalillahData.startDate}
              onChange={handleChange}
              name="startDate"
              id="StartDate"
              placeholder="Enter the Start Date"
              required
            />
          </Grid>
          <Grid item md={6} sm={12} className="form-group">
            <label htmlFor="endDate" className="control-label">
              End Date
            </label>
            <FormControl
              type="date"
              value={jalillahData.endDate}
              onChange={handleChange}
              name="endDate"
              id="endDate"
              placeholder="Enter the End Date"
              required
            />
          </Grid>
          <Grid item md={6} sm={12} className="form-group">
            <label htmlFor="donationLink" className="control-label">
              donation Link
            </label>
            <FormControl
              type="text"
              value={jalillahData.donationLink}
              onChange={handleChange}
              name="donationLink"
              id="donationLink"
              placeholder="Enter the donation Link"
              required
            />
          </Grid>
          <Grid item md={6} sm={12} className="form-group">
            <label htmlFor="target" className="control-label">
              Target
            </label>
            <FormControl
              type="number"
              value={jalillahData.target}
              onChange={handleChange}
              name="target"
              id="target"
              placeholder="Enter target"
              required
            />
          </Grid>
          <Grid item md={6} sm={12} className="form-group">
            <label htmlFor="keyword" className="control-label">
              keyword
            </label>
            <FormControl
              type="text"
              value={jalillahData.keyword}
              onChange={handleChange}
              name="keyword"
              id="keyword"
              placeholder="Enter keyword"
              required
            />
          </Grid>
        </Grid>
        <div
          className="submit"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {popup && (
            <div className={styles.popupOverlay}>
              <div className={styles.popup}>
                <h4>Al Jalillah pages Links</h4>
                <p>
                  You're viewing the Al Jallilah micro-pages links, available in
                  both Arabic and English. Choose whichever you want to visit.
                </p>
                <div className={styles.buttons}>
                  <button type="button" onClick={handleVisitArabicPage}>
                    Arabic Page
                  </button>
                  <button type="button" onClick={handleVisitEnglishPage}>
                    English Page
                  </button>
                  <button type="button" onClick={handleClosePopup}>
                    Close the Popup
                  </button>
                </div>
              </div>
            </div>
          )}
          <Button
            className="create-edit-form-btn"
            type="submit"
            style={{ alignSelf: "flex-end" }}
          >
            Submit
          </Button>
        </div>
      </form>
    </div>
  );
}
export default Jalillah;
