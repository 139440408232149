import * as AWS from "aws-sdk";
import "dotenv";
const uploadOnS3 = async (file, fileName, path) => {
  const s3 = new AWS.S3({
    region: process.env.REGION,
    params: {
      Bucket: "content.quantatel.com",
    },
  });
  const fileType = file.type;
  const fileNameWithExtension = `${fileName}.${file.name.split(".")[1]}`;
  const params = {
    Bucket: "content.quantatel.com",
    Key: `ikhair/images/${path}/${fileNameWithExtension}`,
    Body: file,
    ACL: "public-read",
    ContentType: fileType,
  };
  try {
    const data = await s3.upload(params).promise();
    return data;
  } catch (err) {
    console.error(err, "err");
  }
};
export default uploadOnS3;
